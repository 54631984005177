<template>
    <div id="medicine-list-section" class="create-section mt-sm-5 mt-3">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <form @submit.prevent="editMode ? update() : save()">
                        <div class="mb-3 row g-2">
                            <div class="col-sm-3">
                                <input required :class="{ 'is-invalid': errors['name'] }" placeholder="Medicine Name" v-model="form.name" type="text"
                                       class="form-control mb-3" id="name">
                                 <div v-if="errors['name']" class="invalid-feedback">
                                    {{ errors['name'][0] }}
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <drop-down :class_name="{ 'is-invalid': errors['category_id'] }" title="Category" v_model="category"/>
                                 <div v-if="errors['category_id']" class="invalid-feedback">
                                    {{ errors['category_id'][0] }}
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <input placeholder="Quantity" v-model="form.quantity" type="text"
                                       class="form-control mb-3" id="quantity">
                            </div>
                            <div class="col-sm-3">
                                <input placeholder="Advice" v-model="form.advice" type="text"
                                       class="form-control mb-3" id="advice">
                            </div>
                            <div class="col-sm-1">
                                <input type="submit" class="btn btn-primary w-100"
                                       :value="editMode ? 'Update' : 'Save'">
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-12">
                    <form @submit.prevent="getList()">
                        <div class="mb-3 row">
                            <div class="col-sm-8 ms-auto">
                                <input v-model="table.search" type="text" class="form-control mb-3"
                                       placeholder="Searching...">
                            </div>
                            <div class="col-sm-4">
                                <input type="submit" class="btn btn-primary w-100" value="Searching">
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div>
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Category</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Advice</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="lists.length">
                            <tr v-for="(list, index) in lists">
                                <th scope="row">{{ table.pagination.from + index }}</th>
                                <td>{{ list.name }}</td>
                                <td>{{ list.category ? list.category.name : '' }}</td>
                                <td>{{ list.quantity }}</td>
                                <td>{{ list.advice }}</td>
                                <td>
                                    <update-action type="modal" v-if="$root.$can('medicine', 'medicine-update')" :id="list.id"/>
                                </td>
                                <td>
                                    <delete-action v-if="$root.$can('medicine', 'medicine-delete')" :id="list.id"/>
                                </td>
                            </tr>
                        </template>
                        <template v-if="!lists.length">
                            <tr>
                                <th class="text-center font-weight-bolder" colspan="100%">No data found</th>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <pagination v-if="lists.length>0" :pagination="table.pagination" @paginate="getList()" :offset="5"/>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Pagination          from "@/components/Pagination";

export default {
    name      : "MedicineIndex",
    components: {Pagination},
    data      : () => ({
        lists   : [],
        errors   : [],
        form    : {
            name    : '',
            category: '',
            quantity: '',
            advice: '',
        },
        table   : {
            per_page  : 10,
            search    : '',
            pagination: {
                current_page: 1
            }
        },
        editMode: false
    }),
    mounted() {
        this.getList();
    },
    methods: {
        //-------CRUD------
        getList() {
            let params = {
                ...this.table,
                page: this.table.pagination.current_page,
            };
            ApiService.get('/medicines', {params: params}).then((res) => {
                this.lists            = res.data.data;
                this.table.pagination = res.data.meta;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        save() {
            let params = {
                ...this.form,
                category_id: this.form.category ? this.form.category.id : '',
            }
            ApiService.post('/medicines', params).then((res) => {
                this.errors = []
                this.getList();
                NotificationService.success(res.data.message);
                this.formReset();
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        edit(id) {
            this.editMode = true;
            ApiService.get(`/medicines/${id}`).then((res) => {
                this.form = res.data.data;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        update() {
            let params = {
                ...this.form,
                category_id: this.form.category ? this.form.category.id : '',
            }
            ApiService.update(`/medicines/${this.form.id}`, params).then((res) => {
                this.errors = []
                NotificationService.success(res.data.message);
                this.formReset();
                let updatedItem        = res.data.data
                let foundIndex         = this.lists.findIndex(x => x.id == updatedItem.id);
                this.lists[foundIndex] = updatedItem;
                this.editMode          = false;
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        destroy(id) {
            Swal.fire({
                title             : 'Are you sure?',
                text              : "You won't be able to revert this!",
                icon              : 'warning',
                showCancelButton  : true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor : '#d33',
                confirmButtonText : 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete(`/medicines/${id}`,).then(res => {
                        this.getList();
                        NotificationService.success(res.data.message);
                    }).catch(error => {
                        NotificationService.error(error.response.data.message);
                    })
                }
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        formReset() {
            this.form = {
                name    : '',
                category: '',
                quantity: '',
                advice: '',
            }
        }
        //-------CRUD--------
    }
}
</script>
